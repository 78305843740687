<template>
  <v-container class="d-flex flex-row flex-nowrap login-page-container pa-0" fluid>
    <v-img class="login-image" :lazy-src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-64p.webp')" :src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-1680x960.webp')" alt=""></v-img>
    <div class="left-side">
      <h1 class="title-po login-title white--text">{{ $t('home.title') }}</h1>
      <h2 class="subtitle-po login-text white--text">{{ $t('home.subtitle') }}</h2>
    </div>
    <div class="right-side fill-height">
      <reset-password-form v-if="!isRecovering"></reset-password-form>
      <template v-else>
        <div class="d-flex flex-column align-center justify-center fill-height text-center pa-16">
          <div class="title primary--text mb-8">{{ $t("app.login.resetPassword.forgottenPasswordTitle") }}</div>
          <p>{{ $t("app.login.resetPassword.mailSentPassword")}}</p>
          <router-link :to="{name: 'Login'}">{{ $t("home.login.toLogin") }}</router-link>
        </div>
      </template>
    </div>
  </v-container>
</template>

<script>
import LoginService from "@/Services/LoginService";
import service from "@/Services/TraductionService";

export default {
  name: "ResetPassword",
  components: {
    ResetPasswordForm: () => import("@/Components/Views/Login/ResetPasswordForm")
  },
  computed: {
    lang() {
      return this.$route.query.lang || navigator.language.split("-")[0] || 'en'
    }
  },
  data() {
    return {
      isRecovering: this.$route.query.p !== undefined,
    }
  },
  async mounted() {

    this.changeLang(this.lang)

    if (this.isRecovering) {
      await LoginService.recoverPassword(this.$route.query.p, this.lang)
    }

  }
}
</script>

<style scoped>
.login-page-container {
  background: black;
}

.login-image {
  -webkit-transform: scaleX(-1.05);
  transform: scaleX(-1.05);
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.right-side {
  background: rgb(239,239,239);
  background: linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(215,229,238,1) 100%);
  opacity: 0.95;
  width: 45%;
  height: 100vh;
  z-index: 2000;
}

.left-side {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(0,0,0,.5);
  z-index: 1000;
}

.login-title {
  text-shadow: 1px 1px 8px rgba(0,0,0,.6);
  margin-bottom: 1.5em;
  margin-top: 0;
  text-align: center;
}
.login-text {
  margin-left:4em; margin-right:4em;
  text-align: center;
  text-shadow: 1px 1px 5px rgba(0,0,0,.5);
}
</style>